<template>
  <div class="footer">
    <div class="footer__wrapper">
      <Section limiter>
        <Column>
          <Row
            class="footer__gosuslugi"
            :class="
              $viewport.desktop
                ? 'grid-12 formation-portal-margin-80'
                : 'formation-portal-margin-30'
            "
            v-if="$viewport.desktop"
          >
<!--            <a class="footer__gosuslugi-link" href="https://gosuslugi.ru">-->
<!--              <img-->
<!--                class="footer__gosuslugi-image"-->
<!--                :src="require(`@/assets/images/gosuslugi${$viewport.mobile ? '-mobile' : ''}.png`)"-->
<!--              />-->
<!--            </a>-->
          </Row>
          <component
            :is="$viewport.desktop ? 'Row' : 'Column'"
            :class="
              $viewport.desktop
                ? 'grid-12 formation-portal-margin-80'
                : 'formation-portal-margin-30'
            "
            justify="between"
          >
            <component
              :is="$viewport.desktop ? 'Row' : 'Column'"
              :class="$viewport.desktop ? 'grid-8' : ''"
              justify="between"
            >
              <Column :class="$viewport.desktop ? 'grid-4' : 'formation-portal-margin-30'">
                <div class="footer__association-title">инициатор проекта</div>
                <Divider class="footer__association-divider" color="fff" />
                <Row>
                  <img
                    class="footer__association-icon"
                    :src="require(`@/assets/images/tomsk.png`)"
                  />
                  <a href="https://tomsk.gov.ru/" class="footer__association-icon-title"
                    >Проект инициирован Администрацией Томской области</a
                  >
                </Row>
              </Column>
              <Column :class="$viewport.desktop ? 'grid-4' : 'formation-portal-margin-30'">
                <div class="footer__association-title">разработчик</div>
                <Divider class="footer__association-divider" color="fff" />
                <Row>
                  <img
                    class="footer__association-icon"
                    :src="require(`@/assets/images/svg/rosatom.svg`)"
                  />
                  <a href="https://rusatom-utilities.ru/" class="footer__association-icon-title"
                    >Русатом Инфрастуктурные решения</a
                  >
                </Row>
              </Column>
            </component>
            <Column :class="$viewport.desktop ? 'grid-4' : ''">
              <div class="footer__association-title">поделиться в соцсетях</div>
              <Divider class="footer__association-divider" color="fff" />
              <Row>
                <div class="social-networks">
                  <div
                    :key="socialNetwork.id"
                    class="footer__social-network social-network"
                    v-for="socialNetwork in socialNetworks"
                  >
                    <social-sharing
                      :url="path"
                      title="Туристический гид Томской области"
                      inline-template
                    >
                      <div class="block">
                        <network
                          :network="`${
                            socialNetwork.name !== 'ok' ? socialNetwork.name : 'odnoklassniki'
                          }`"
                        >
                          <div
                            :class="`social-network__icon-${socialNetwork.name}
                              social-network__icon-${socialNetwork.name}-active`"
                            class="social-network__icon"
                          >
                            <Icon
                              :viewport="`${socialNetwork.viewport}`"
                              :xlink="`${socialNetwork.name}-icon`"
                            />
                          </div>
                        </network>
                      </div>
                    </social-sharing>
                  </div>
                </div>
              </Row>
            </Column>
          </component>
          <Row
            :is="$viewport.desktop ? 'Row' : 'Column'"
            :class="
              $viewport.desktop
                ? 'grid-12 formation-portal-margin-30'
                : 'formation-portal-margin-30'
            "
            justify="between"
          >
            <Column :class="$viewport.desktop ? 'grid-4' : 'formation-portal-margin-30'">
              <div class="footer__association-title">информация</div>
              <Divider class="footer__association-divider" color="fff" />
              <Column>
                <ul class="footer__association-ul">
                  <li>
                    <div style="padding-bottom: 1rem; color: white; font-weight: bold; font-size: 12px; line-height: 1.5;">
                      Для уточнения стоимости ПО необходимо отправить запрос на адрес электронной почты:<br/> <a href="mailto:rir-smart@rosatom.ru">rir-smart@rosatom.ru</a>
                    </div>
                  </li>
                </ul>
              </Column>
            </Column>
            <Column :class="$viewport.desktop ? 'grid-4' : 'formation-portal-margin-30'">
              <div class="footer__association-title">информация о ПО</div>
              <Divider class="footer__association-divider" color="fff" />
              <Column>
                <div class="saas-block">
                  <a href="../Информация_об_установке_SaaS.pdf" target="_blank">
                    Программа для ЭВМ не требует установки и распространяется в виде SAAS-решения.
                  </a>
                </div>
                <ul class="footer__association-ul">
                  <li class="footer__association-li">
                    <a
                      href="../Описание_функциональных_характеристик_Гид_путеводитель.pdf"
                      class="footer__association-icon-full-title"
                      target="_blank"
                    >
                      Описание функциональных характеристик сайта
                    </a>
                  </li>
                  <li class="footer__association-li">
                    <a
                      href="../Руководство_пользователя_Гид_путеводлитель.pdf"
                      class="footer__association-icon-full-title"
                      target="_blank"
                    >
                      Руководство пользователя
                    </a>
                  </li>
                  <li>
                    <a
                      href="../Руководство_администратора_Гид_путеводлитель.pdf"
                      class="footer__association-icon-full-title"
                      target="_blank"
                    >
                      Руководство администратора
                    </a>
                  </li>
                </ul>
              </Column>
            </Column>
            <Column :class="$viewport.desktop ? 'grid-4' : 'formation-portal-margin-30'">
              <div class="footer__association-title">обратная связь</div>
              <Divider class="footer__association-divider" color="fff" />
              <Row>
                <a href="mailto:rir-smart@rosatom.ru" class="footer__association-icon-full-title">
                  Сообщить о проблеме
                </a>
              </Row>
            </Column>
          </Row>
        </Column>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    qr() {
      const { path } = this.$route
      if (path === '/') return `${this.$host}/qr/index.svg`
      return `${this.$host}/qr/${path.split('/').join('_').slice(1)}.svg`
    },
  },
  data() {
    return {
      path: window.location.href,
      socialNetworks: [
        {
          name: 'vk',
          viewport: '0 0 29 17',
        },
        // {
        //   name: 'facebook',
        //   viewport: '0 0 10 23',
        // },
        {
          name: 'ok',
          viewport: '0 0 14 24',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import './../assets/style/utilits/index';

.footer {
  .saas-block {
    padding-bottom: 1rem;
    color: darkgrey;
    font-weight: bold;
    line-height: 1.5;

    a {
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .social-networks {
    @include size(100%);
    @include flex(flex, row);
  }

  .social-network {
    @include size(55px, 55px);
    margin: 0 30px 0 0;
  }

  &__wrapper {
    background-color: $ag-footer-background;
  }

  &__gosuslugi {
    @include size(100%, auto);
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;

    &-link {
      @include size(100%, auto);
      display: inline-block;
    }

    &-image {
      @include size(100%, auto);
    }
  }

  &__association {
    &-title {
      margin: 0 0 10px;
      @include font(12px, normal);
      color: $white;
    }

    &-divider {
      margin: 0 0 20px;
    }

    &-icon {
      @include size(55px, 55px);
      margin: 0 15px 0 0;

      &-title {
        @include size(193px);
        @include font(16px, bold, 20px);
        color: $white;
      }

      &-full-title {
        @include font(16px, bold, 20px);
        color: $white;
      }
    }

    &-ul {
    }

    &-li {
      margin: 0 0 15px;
    }
  }

  &__qr {
    @include size(100px, 100px);
  }
}
</style>
